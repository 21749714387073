var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.create)}}},[_c('v-card',[_c('v-card-title',[_vm._v(" Create answer ")]),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"quiz","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.quizes,"item-value":"id","item-text":"title","label":"Quiz","error-messages":errors},on:{"change":function($event){return _vm.getQuestionByID(_vm.answer.quiz_id)}},model:{value:(_vm.answer.quiz_id),callback:function ($$v) {_vm.$set(_vm.answer, "quiz_id", $$v)},expression:"answer.quiz_id"}})]}}],null,true)}),(_vm.answer.quiz_id)?_c('ValidationProvider',{attrs:{"name":"quiz","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.questions,"item-value":"id","item-text":"title","label":"Questions","error-messages":errors},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$strippedContent(item.title))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$strippedContent(item.title))+" ")]}}],null,true),model:{value:(_vm.answer.question_id),callback:function ($$v) {_vm.$set(_vm.answer, "question_id", $$v)},expression:"answer.question_id"}})]}}],null,true)}):_vm._e(),(_vm.answer.question_id)?[_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Title","type":"text","required":""},model:{value:(_vm.answer.title),callback:function ($$v) {_vm.$set(_vm.answer, "title", $$v)},expression:"answer.title"}})]}}],null,true)}),_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-list-status ")]),_c('BtnToggle',{attrs:{"positive":{text: 'Correct'},"negative":{text: 'Wrong'}},model:{value:(_vm.answer.is_correct),callback:function ($$v) {_vm.$set(_vm.answer, "is_correct", $$v)},expression:"answer.is_correct"}}),_c('h3',{staticClass:"mt-4"},[_vm._v(" Status ")]),_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-check-circle-outline ")]),_c('BtnToggle',{attrs:{"positive":{text: 'Active'},"negative":{text: 'Inactive'}},model:{value:(_vm.answer.status),callback:function ($$v) {_vm.$set(_vm.answer, "status", $$v)},expression:"answer.status"}})]:_vm._e()],2),_c('v-card-actions',{staticClass:"pb-3"},[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","color":"primary","loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('action.create'))+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }